<template>
  <div>
    LayoutList

    <div v-if="lay_ready">
      <div v-for="place in lay" :key="place.info.id">
        {{place.info.name}}
        <br>

        <div v-for="layout in place.data" :key="layout.id">
          {{layout.name}}{{layout.seat_total}}
          <br>
        </div>
        <hr>

      </div>
    </div>

  </div>
</template>

<script>
import user from "@/store/modules/user";

export default {
  name: "LayoutList",

  data: () => ({

    lay_permitted_list:[],
    place_permitted_list:[],

    id: 7,

    lay_ready: false,
    lay: {},

  }),

  created() {

    if (this.$store.getters.isAuthenticated){

      let pr = this.$store.state.api.loadPermittedLayout(user.state.selectel_path);

      pr.then( data =>{

        console.log(data);

        this.lay_permitted_list   = data.layout;
        this.place_permitted_list = data.layout_place;

        this.loadLayout();

      })

      console.log('onload');

    }

  },


  methods:{

    loadPermittedLayout: function(){








    },

    loadLayout: function(){

      let pr = this.$store.state.api.loadLayoutsGroupByPlaces(this.lay_permitted_list);

      pr.then( data =>{
        this.lay = data;
        this.lay_ready = true;
      })

    }


  }
}
</script>

<style scoped>

</style>
